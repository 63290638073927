@import "../../styles/variables.scss";

.cyber-threats {
    background-color: $indigo;
    padding-bottom: 58px;

    @media (min-width: $breakpoint-min-tablet) {
        padding-bottom: 64px;
    }

    @media (min-width: $breakpoint-min-desktop) {
        padding-bottom: 32px;
    }

    &__title {
        margin-top: 55px;
        margin-bottom: 5px;

        @media (min-width: $breakpoint-min-tablet) {
            margin-top: 75px;
            margin-bottom: 10px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            margin-top: 92px;
            margin-bottom: 24px;
        }
    }

    &__subtitle {
        margin-bottom: 34px;

        @media (min-width: $breakpoint-min-tablet) {
            margin-bottom: 57px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            margin-bottom: 0;
        }
    }

    &__cards {
        @media (min-width: $breakpoint-min-desktop) {
            margin-top: 112px;
        }
    }
}
