@import "../../styles/variables.scss";

.how-exactly {
    overflow: hidden;

    &__title {
        margin-top: 15px;
        margin-bottom: 5px;
    }

    &__subtitle {
        margin-bottom: 65px;

        @media (min-width: $breakpoint-min-tablet) {
            margin-bottom: 91px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            margin-bottom: 122px;
        }
    }

    &__request-demo {
        z-index: 2;
        margin-top: 88px;
        margin-bottom: 74px;

        @media (min-width: $breakpoint-min-tablet) {
            margin-top: 60px;
            margin-bottom: 133px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            margin-top: 116px;
            margin-bottom: 167px;
        }

        // @media (min-width: $breakpoint-min-desktop-hd) {
        //     margin-top: 184px;
        //     margin-bottom: 206px;
        // }
    }

    &__glitch {
        z-index: 1;
        background-image: url("./images/glitch.gif");
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        bottom: 0;
        right: -67px;
        width: 340px;
        height: 202px;

        @media (min-width: $breakpoint-min-tablet) {
            bottom: 16px;
            right: -73px;
            width: 419px;
            height: 249px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            bottom: 42px;
            right: -140px;
            width: 543px;
            height: 323px;
        }

        // @media (min-width: $breakpoint-min-desktop-hd) {
        //     bottom: 67px;
        //     right: -112px;
        //     width: 543px;
        //     height: 323px;
        // }
    }

    &__link-wrapper {
        position: relative;
        z-index: 1;

        &::after {
            content: "";
            position: absolute;
            z-index: 2;
            display: inline-block;
            width: 56px;
            height: 34px;
            background-image: url("./images/cursor.gif");
            background-repeat: no-repeat;
            background-size: contain;
            transform: rotate(90deg);
            left: 47px;
            bottom: 26px;
            pointer-events: none;

            @media (min-width: $breakpoint-min-tablet) {
                transform: rotate(0deg);
                width: 66px;
                height: 40px;
                left: -49px;
                bottom: 4px;
            }

            @media (min-width: $breakpoint-min-desktop) {
                width: 90px;
                height: 54px;
                left: -74px;
                bottom: 3px;
            }

            // @media (min-width: $breakpoint-min-desktop-hd) {
            //     width: 133px;
            //     height: 80px;
            //     left: -103px;
            //     bottom: 12px;
            // }
        }
    }
}
