@import "../../styles/variables.scss";

.what-you-get-card {
    position: relative;
    padding-top: 56px;

    @media (min-width: $breakpoint-min-tablet) {
        padding-top: 78px;
    }

    @media (min-width: $breakpoint-min-desktop) {
        padding-top: 96px;
    }

    &_cloud::after,
    &_eye::after,
    &_pie::after,
    &_plane::after,
    &_puzzle::after,
    &_ring::after,
    &_rocket::after,
    &_wallet::after {
        display: inline-block;
        position: absolute;
        top: 20px;
        left: 0;
        width: 26px;
        height: 26px;
        content: "";
        background-repeat: no-repeat;
        background-size: contain;

        @media (min-width: $breakpoint-min-tablet) {
            top: 37px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            width: 32px;
            height: 32px;
            top: 57px;
        }
    }

    &_cloud {
        &:after {
            background-image: url("./images/cloud.svg");
        }
    }
    &_eye {
        &:after {
            background-image: url("./images/eye.svg");
        }
    }
    &_pie {
        &:after {
            background-image: url("./images/pie.svg");
        }
    }
    &_plane {
        &:after {
            background-image: url("./images/plane.svg");
        }
    }
    &_puzzle {
        &:after {
            background-image: url("./images/puzzle.svg");
        }
    }
    &_ring {
        &:after {
            background-image: url("./images/ring.svg");
        }
    }
    &_rocket {
        &:after {
            background-image: url("./images/rocket.svg");
        }
    }
    &_wallet {
        &:after {
            background-image: url("./images/wallet.svg");
        }
    }
}
