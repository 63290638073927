@import "./variables.scss";

.text-xl {
    font-size: 26px;
    line-height: 24px;
    color: $dark;
    letter-spacing: 1px;

    @media (min-width: $breakpoint-min-tablet) {
        font-size: 48px;
        line-height: 48px;
    }

    @media (min-width: $breakpoint-min-desktop) {
        font-size: 72px;
        line-height: 72px;
    }
}

.text-md {
    font-size: 16px;
    line-height: 19px;
    color: $dark;
    letter-spacing: 0.3px;

    @media (min-width: $breakpoint-min-tablet) {
        font-size: 21px;
        line-height: 25px;
    }

    @media (min-width: $breakpoint-min-desktop) {
        font-size: 36px;
        line-height: 43px;
    }
}

.text-lg {
    font-size: 18px;
    line-height: 18px;
    color: $dark;
    letter-spacing: 0.3px;

    @media (min-width: $breakpoint-min-tablet) {
        font-size: 24px;
        line-height: 24px;
    }

    @media (min-width: $breakpoint-min-desktop) {
        font-size: 48px;
        line-height: 48px;
    }
}

.text-sm {
    font-size: 14px;
    line-height: 17px;
    color: $dark;
    letter-spacing: 0.3px;

    @media (min-width: $breakpoint-min-tablet) {
        font-size: 18px;
        line-height: 22px;
    }

    @media (min-width: $breakpoint-min-desktop) {
        font-size: 24px;
        line-height: 29px;
    }
}

.text-xs {
    font-size: 12px;
    line-height: 14px;
    color: $dark;

    @media (min-width: $breakpoint-min-tablet) {
        font-size: 14px;
        line-height: 17px;
    }

    @media (min-width: $breakpoint-min-desktop) {
        font-size: 18px;
        line-height: 22px;
    }
}

.text-button {
    font-size: 14px;
    line-height: 14px;
    color: $violet;

    @media (min-width: $breakpoint-min-tablet) {
        font-size: 16px;
        line-height: 16px;
    }

    @media (min-width: $breakpoint-min-desktop) {
        font-size: 21px;
        line-height: 24px;
    }
}

.text-bold {
    font-family: "museo_sans_cyrl600", sans-serif !important;
    font-weight: 600;
}

.text-thin {
    font-family: "museo_sans_cyrl100", sans-serif !important;
    font-weight: 100;
}

.text-white {
    color: $white;
}

.text-indigo {
    color: $indigo;
}

.text-violet-light {
    color: $violet-light;
}
