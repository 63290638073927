@import "./variables.scss";
@import "./reset.scss";
@import "./fonts-loader.scss";
@import "./typography.scss";
@import "./button.scss";

body {
    font-family: "museo_sans_cyrl300", sans-serif !important;
    font-weight: 300;
    min-width: 320px;
    background-color: $white;
}
