@import "../../styles/variables.scss";

.recent-real-life-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 56px;
    margin-top: 26px;
    min-height: 99px;

    @media (min-width: $breakpoint-min-tablet) {
        min-height: 141px;
        margin-top: 83px;
    }

    @media (min-width: $breakpoint-min-desktop) {
        min-height: 182px;
        margin-top: 103px;
    }

    &::before {
        position: absolute;
        left: 0;
        top: -15px;
        font-size: 120px;
        line-height: 100px;
        color: $violet-dark;
        font-family: "museo_sans_cyrl600", sans-serif !important;
        font-weight: 600;
        z-index: 1;

        @media (min-width: $breakpoint-min-tablet) {
            font-size: 200px;
            line-height: 200px;
            top: -73px;
            left: -25px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            font-size: 300px;
            line-height: 300px;
            top: -101px;
            left: -61px;
        }
    }

    &_1::before {
        content: "1";
    }

    &_2::before {
        content: "2";
    }

    &_3::before {
        content: "3";
    }

    &__text {
        z-index: 2;

        @media (min-width: $breakpoint-min-tablet) {
            width: 289px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            width: 320px;
        }
    }

    &__read-more {
        position: relative;
        padding-right: 25px;
        text-decoration: none;
        width: max-content;
        z-index: 3;

        &:hover {
            cursor: pointer;
        }

        &::after {
            position: absolute;
            top: 25%;
            right: 0;
            content: "";
            width: 0.35em;
            height: 0.35em;
            border-bottom: 2px solid currentColor;
            border-right: 2px solid currentColor;
            transform: rotate(45deg);
            z-index: 2;
            display: inline-block;
            transition: transform 0.1s linear;
        }

        &_active {
            &::after {
                transform: rotate(225deg);
            }
        }
    }

    &__read-more-content {
        padding-left: 56px;
        margin-top: 0;
        z-index: 2;

        @media (min-width: $breakpoint-min-tablet) {
            width: 289px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            width: 320px;
        }

        overflow: hidden;
        max-height: 0;
        opacity: 0;
        transition: all 0.3s ease-in-out;

        &_shown {
            margin-top: 15px;
            max-height: 800px;
            opacity: 1;

            @media (min-width: $breakpoint-min-tablet) {
                margin-top: 25px;
            }

            @media (min-width: $breakpoint-min-desktop) {
                margin-top: 30px;
            }
        }
    }
}
