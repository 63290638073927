@import "../../styles/variables.scss";

.why-the-tailored-card {
    background-color: $gray;
    margin-top: 10px;
    padding: 29px 14px 25px;

    @media (min-width: $breakpoint-min-tablet) {
        margin-top: 23px;
        padding: 30px 227px 26px 42px;
    }

    @media (min-width: $breakpoint-min-desktop) {
        margin-top: 26px;
        padding: 30px 304px 25px 61px;
    }

    &__title {
        margin-bottom: 5px;

        @media (min-width: $breakpoint-min-tablet) {
            margin-bottom: 10px;
        }
    }
}
