@import "../../styles/variables.scss";

.how-exactly-card {
    position: relative;

    &_with-arrow {
        padding-top: 110px;

        @media (min-width: $breakpoint-min-tablet) {
            padding-top: 0;
        }

        &:before {
            width: 10px;
            content: " ";
            background-image: url("./images/arrow.svg");
            background-repeat: no-repeat;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 35px;
            height: 50px;

            @media (min-width: $breakpoint-min-tablet) {
                width: 15px;
                background-position: right;
                background-size: cover;
                left: -156px;
                top: -75px;
                height: 100px;
                transform: rotate(-90deg);
            }

            @media (min-width: $breakpoint-min-desktop) {
                left: -213px;
                top: -85px;
            }
        }
    }

    &__icon {
        margin-bottom: 20px;
    }

    &_icon-sunglasses::after,
    &_icon-pulse::after,
    &_icon-aim::after {
        width: 30px;
        height: 30px;
        content: " ";
        background-image: url("./images/sunglasses.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        left: 0px;
        bottom: 67px;

        @media (min-width: $breakpoint-min-tablet) {
            bottom: initial;
            left: 0px;
            top: -40px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            width: 40px;
            height: 40px;
            top: -55px;
        }
    }

    &_icon-sunglasses::after {
        background-image: url("./images/sunglasses.svg");
    }

    &_icon-pulse::after {
        background-image: url("./images/pulse.svg");
    }

    &_icon-aim::after {
        background-image: url("./images/aim.svg");
    }
}
