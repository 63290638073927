@import "../../styles/variables.scss";
@import "../../styles/typography.scss";

.advantage-card {
    position: relative;
    width: 100%;
    background-color: $indigo;
    padding: 64px 20px 29px 17px;
    box-shadow: 5px -5px 30px 10px rgba(0, 0, 0, 0.1);

    @media (min-width: $breakpoint-min-tablet) {
        padding: 74px 17px 36px;
    }

    @media (min-width: $breakpoint-min-desktop) {
        padding: 83px 32px 53px;
    }

    &_key,
    &_lock,
    &_guard {
        &:after {
            content: "";
            background-repeat: no-repeat;
            background-size: contain;
            top: 17px;
            left: 17px;
            position: absolute;
            display: inline-block;

            @media (min-width: $breakpoint-min-tablet) {
                top: 30px;
            }

            @media (min-width: $breakpoint-min-desktop) {
                left: 32px;
            }
        }
    }

    &_key {
        &:after {
            background-image: url("./images/key.svg");
            width: 25px;
            height: 25px;

            @media (min-width: $breakpoint-min-desktop) {
                width: 32px;
                height: 32px;
            }
        }
    }

    &_guard {
        &:after {
            background-image: url("./images/guard.svg");
            width: 22px;
            height: 25px;

            @media (min-width: $breakpoint-min-desktop) {
                width: 29px;
                height: 32px;
            }
        }
    }

    &_lock {
        &:after {
            background-image: url("./images/lock.svg");
            width: 18px;
            height: 25px;

            @media (min-width: $breakpoint-min-desktop) {
                width: 24px;
                height: 32px;
            }
        }
    }

    &__title {
        margin: 0 0 5px;

        @media (min-width: $breakpoint-min-desktop) {
            margin: 0 0 15px;
        }
    }
}
