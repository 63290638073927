@import "../../styles/variables.scss";

.header {
    display: flex;
    position: relative;
    justify-content: space-around;
    overflow: hidden;
    margin-top: 44px;
    background: linear-gradient(
        0deg,
        rgba(135, 0, 253, 0.76) 0.34%,
        rgba(232, 0, 255, 0.43242) 30.53%,
        rgba(255, 0, 255, 0.35318) 50.03%,
        rgba(0, 229, 250, 0.39) 87.29%
    );

    @media (min-width: $breakpoint-min-tablet) {
        margin-top: 62px;
        padding-bottom: 143px;
    }

    @media (min-width: $breakpoint-min-desktop) {
        margin-top: 84px;
        padding-bottom: 0;
    }

    &__content-wrapper {
        z-index: 2;
    }

    &__title-wrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    &__title {
        margin-bottom: 5px;

        @media (min-width: $breakpoint-min-tablet) {
            margin-bottom: 10px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            margin-bottom: 15px;
        }

        // @media (min-width: $breakpoint-min-desktop-hd) {
        //     margin-bottom: 30px;
        // }
    }

    &__lock {
        display: flex;
        justify-content: center;
        flex: 1;
        background-image: url("./images/lock.webp");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50% 50%;
        min-height: 268px;

        @media (min-width: $breakpoint-min-tablet) {
            min-height: 403px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            min-height: 530px;
        }

        // @media (min-width: $breakpoint-min-desktop-hd) {
        //     min-height: 720px;
        // }
    }

    &__glitch {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%);
        background-image: url("./images/glitch.gif");
        background-repeat: no-repeat;
        background-size: contain;
        background-repeat: no-repeat;
        background-size: cover;
        width: 268px;
        height: 159px;
        z-index: 1;
        z-index: 1;

        @media (min-width: $breakpoint-min-tablet) {
            top: calc(50% - (143px / 2));
            width: 498px;
            height: 256px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            top: 50%;
            width: 639px;
            height: 380px;
        }
    }
}
