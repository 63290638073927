@import "../../styles/variables.scss";

.cyber-threats-form {
    &__fieldset {
        display: flex;
        flex-direction: column;
    }

    &__wrapper {
        background-color: $violet-dark;
        padding: 31px 20px 43px;

        @media (min-width: $breakpoint-min-tablet) {
            padding: 43px 75px 48px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            padding: 47px 48px 59px;
        }
    }

    &__title {
        margin-bottom: 18px;
    }

    &__input {
        font: inherit;
        margin-bottom: 18px;
        background-color: #f5f8fa;
        padding: 0 15px;
        min-height: 27px;
        height: 40px;
        line-height: 22px;
        color: #33475b;
        border: 1px solid #cbd6e2;
        border-radius: 3px;

        &:focus {
            outline: none;
            border-color: rgba(82, 168, 236, 0.8);
        }
    }

    &__submit {
        width: fit-content;
        cursor: pointer;
        display: inline-block;
        font-weight: 700;
        line-height: 12px;
        position: relative;
        text-align: center;
        transition: all 0.15s linear;
        background-color: #ff7a59;
        border-color: #ff7a59;
        color: #fff;
        border-radius: 3px;
        border-style: solid;
        border-width: 1px;
        font-size: 14px;
        padding: 12px 24px;

        &:focus {
            background-color: #ff8f73;
            border-color: #ff8f73;
        }
    }
}
