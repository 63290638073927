@import "../../styles/variables.scss";

.vital-areas {
    margin-top: -40px;
    z-index: 1;
    position: relative;

    @media (min-width: $breakpoint-min-tablet) {
        margin-top: -187px;
    }

    @media (min-width: $breakpoint-min-desktop) {
        margin-top: -70px;
    }

    &__card {
        display: flex;

        &_with-margin {
            margin-top: 5px;

            @media (min-width: $breakpoint-min-tablet) {
                margin-top: 0;
            }
        }
    }

    &__title {
        margin-top: 45px;
        margin-bottom: 5px;
        color: $dark;

        @media (min-width: $breakpoint-min-tablet) {
            margin-top: 60px;
            margin-bottom: 10px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            margin-top: 65px;
        }
    }

    &__arrows-list {
        margin-top: 23px;
        margin-bottom: 164px;

        @media (min-width: $breakpoint-min-tablet) {
            margin-top: 43px;
            margin-bottom: 57px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            margin-top: 62px;
            margin-bottom: 137px;
        }

        > li:not(:first-child) {
            margin-top: 30px;
        }
    }

    &__arrows-list-item {
        position: relative;
        padding-left: 68px;

        @media (min-width: $breakpoint-min-desktop-hd) {
            padding-left: 200px;
        }

        &:after {
            content: "";
            width: 50px;
            height: 8px;
            background-repeat: no-repeat;
            background-size: contain;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            position: absolute;
            display: inline-block;
            background-image: url("./images/arrow.svg");

            @media (min-width: $breakpoint-min-desktop-hd) {
                width: 180px;
                height: 15px;
                background-size: cover;
                background-image: url("./images/arrow-hd.svg");
            }
        }
    }

    &__laptop {
        position: absolute;
        bottom: -65px;
        left: 50%;
        z-index: 2;

        transform: translateX(-50%);
        width: 218px;
        height: 100%;
        max-height: 224px;

        @media (min-width: $breakpoint-min-tablet) {
            left: 0;
            bottom: -20px;
            transform: none;
            max-height: 285px;
            width: 272px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            bottom: -25px;
            max-height: 397px;
            width: 379px;
            left: -8vw;
        }

        // @media (min-width: $breakpoint-min-desktop-hd) {
        //     left: -8vw;
        // }

        &:after {
            content: "";
            position: absolute;
            background-image: url("./images/glitch-laptop.gif");
            background-repeat: no-repeat;
            background-size: cover;
            z-index: -1;
            left: 100px;
            top: 25px;
            transform: translateX(-50%);
            width: 136px;
            height: 41px;
            display: inline-block;

            @media (min-width: $breakpoint-min-tablet) {
                width: 175px;
                height: 52px;
                left: 127px;
                top: 30px;
            }

            @media (min-width: $breakpoint-min-desktop) {
                width: 243px;
                height: 73px;
                left: 177px;
                top: 41px;
            }
        }

        &::before {
            content: "";
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: contain;
            z-index: 2;
            display: inline-block;
            background-image: url("./images/laptop.webp");
        }
    }
}
