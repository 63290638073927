$violet: #8700fd;
$violet-light: #bd72ff;
$violet-dark: #510c98;
$gray: #f2f2f2;
$sky: #00e5fa;
$white: #ffffff;
$dark: #080844;
$indigo: #440981;

$breakpoint-min-tablet: 768px;
$breakpoint-min-desktop: 992px;
$breakpoint-min-desktop-hd: 1920px;

$h1-size: 26px;
$h1-size-tablet: 48px;
$h1-size-desktop: 72px;
$h1-size-desktop-hd: 96px;

$h1-subtitle-size: 16px;
$h1-subtitle-size-tablet: 21px;
$h1-subtitle-size-desktop: 36px;
$h1-subtitle-size-desktop-hd: 48px;

$h2-size: 16px;
$h2-size-tablet: 18px;
$h2-size-desktop: 24px;
$h2-size-desktop-hd: 32px;

$h2-subtitle-size: 12px;
$h2-subtitle-size-tablet: 14px;
$h2-subtitle-size-desktop: 22px;
$h2-subtitle-size-desktop-hd: 24px;

$text-size: 12px;
$text-size-tablet: 14px;
$text-size-desktop: 18px;
$text-size-desktop-hd: 24px;
