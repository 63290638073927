@import "../../styles/variables.scss";

.ticker {
    &__wrapper {
        position: relative;
        width: 100%;
        height: 50px;
        background-color: $indigo;
        margin: 35px 0;

        @media (min-width: $breakpoint-min-desktop) {
            height: 68px;
        }
    }

    &__content {
        top: 50%;
        transform: translateY(-50%);
    }

    &__item {
        padding-right: 50px;

        @media (min-width: $breakpoint-min-tablet) {
            padding-right: 70px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            padding-right: 90px;
        }
    }
}
