@import "../../styles/variables.scss";

.soc-covers-card {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: $violet-dark;
    padding: 26px 14px;
    box-shadow: 5px -5px 30px 10px rgba(0, 0, 0, 0.1);
    z-index: 2;
    position: relative;

    @media (min-width: $breakpoint-min-tablet) {
        padding: 33px 20px;
    }

    @media (min-width: $breakpoint-min-desktop) {
        padding: 33px 50px;
    }
}
