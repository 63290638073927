@import "../../styles/variables.scss";

.recent-real-life {
    overflow: hidden;
    padding-bottom: 240px;

    @media (min-width: $breakpoint-min-tablet) {
        padding-bottom: 90px;
    }

    @media (min-width: $breakpoint-min-desktop) {
        padding-bottom: 167px;
    }

    background: linear-gradient(0deg, transparent 0, transparent 65px, $indigo 0, $indigo);

    @media (min-width: $breakpoint-min-tablet) {
        background: linear-gradient(0deg, transparent 0, transparent 50px, $indigo 0, $indigo);
    }

    @media (min-width: $breakpoint-min-desktop) {
        background: linear-gradient(0deg, transparent 0, transparent 60px, $indigo 0, $indigo);
    }

    &__content {
        position: relative;
    }

    &__title {
        margin-top: 59px;
        margin-bottom: 5px;

        @media (min-width: $breakpoint-min-tablet) {
            margin-top: 72px;
            margin-bottom: 10px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            margin-top: 83x;
        }
    }

    &__cage {
        position: absolute;
        bottom: -238px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        width: 284px;
        height: 100%;
        max-height: 234px;

        @media (min-width: $breakpoint-min-tablet) {
            bottom: -117px;
            left: -15px;
            transform: none;
            width: 394px;
            max-height: 323px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            bottom: -197px;
            left: -32px;
            width: 549px;
            max-height: 451px;
        }

        &:after {
            content: "";
            position: absolute;
            background-image: url("./images/bugs.gif");
            background-repeat: no-repeat;
            background-size: contain;
            z-index: -1;
            width: 198px;
            height: 128px;
            left: 12px;
            top: 48px;
            display: inline-block;

            @media (min-width: $breakpoint-min-tablet) {
                left: 68px;
                top: 71px;
                width: 254px;
                height: 178px;
            }

            @media (min-width: $breakpoint-min-desktop) {
                left: 100px;
                top: 89px;
                width: 412px;
                height: 248px;
            }
        }

        &::before {
            content: "";
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: contain;
            z-index: 2;
            display: inline-block;
            background-image: url("./images/cage.webp");
        }
    }
}
