@import "../../styles/variables.scss";

.what-they-say-carousel {
    position: relative;
    padding-bottom: 97px;

    @media (min-width: $breakpoint-min-tablet) {
        padding-bottom: 120px;
    }

    @media (min-width: $breakpoint-min-desktop) {
        padding-bottom: 181px;
    }

    &__slide {
        position: relative;
        padding: 30px 30px 34px;
        border: 3px solid $gray;

        @media (min-width: $breakpoint-min-tablet) {
            padding: 36px 103px 39px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            padding: 64px 60px 58px;
        }
    }

    &__slide-inner {
        display: flex;
    }

    &__quote {
        display: block;
        margin-bottom: 18px;
    }

    &__button {
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;

        width: 100px;
        height: 9px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        position: absolute;
        bottom: 35px;
        padding: 30px 0;

        @media (min-width: $breakpoint-min-tablet) {
            bottom: 48px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            bottom: 97px;
        }

        @media (min-width: $breakpoint-min-desktop-hd) {
            bottom: 81px;
        }

        &::-moz-focus-inner {
            border: 0;
            padding: 0;
        }

        &_back {
            background-image: url("./images/arrow.svg");
            left: 30px;
        }

        &_next {
            background-image: url("./images/arrow.svg");
            transform: rotate(180deg);
            right: 30px;
        }
    }
}
