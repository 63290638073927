@import "../../styles/variables.scss";

.what-they-say {
    position: relative;
    overflow: hidden;
    padding-top: 45px;
    padding-bottom: 35px;

    @media (min-width: $breakpoint-min-tablet) {
        padding-bottom: 104px;
    }

    @media (min-width: $breakpoint-min-desktop) {
        padding-bottom: 141px;
    }

    &__title {
        margin-bottom: 16px;

        @media (min-width: $breakpoint-min-tablet) {
            margin-bottom: 26px;
        }
    }

    &::after {
        content: "";
        position: absolute;
        display: inline-block;
        width: 168px;
        height: 126px;
        background-image: url("./images/magnifier.webp");
        background-repeat: no-repeat;
        background-size: contain;
        left: 0;
        bottom: 36px;

        @media (min-width: $breakpoint-min-tablet) {
            width: 302px;
            height: 226px;
            bottom: 35px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            width: 441px;
            height: 330px;
            bottom: 0;
        }
    }
}
