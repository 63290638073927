@font-face {
    font-family: "museo_sans_cyrl100";
    src: url("museo_sans_cyrl_100-webfont.woff2") format("woff2"),
        url("museo_sans_cyrl_100-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "museo_sans_cyrl300";
    src: url("museo_sans_cyrl_300-webfont.woff2") format("woff2"),
        url("museo_sans_cyrl_300-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "museo_sans_cyrl700";
    src: url("museo_sans_cyrl_700-webfont.woff2") format("woff2"),
        url("museo_sans_cyrl_700-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
