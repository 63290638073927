@import "../../styles/variables.scss";

.why-the-tailored {
    overflow: hidden;

    &__content {
        position: relative;
        padding-top: 80px;
        padding-bottom: 36px;

        @media (min-width: $breakpoint-min-tablet) {
            padding-bottom: 68px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            padding-top: 121px;
        }
    }

    &__title {
        margin-bottom: 13px;

        @media (min-width: $breakpoint-min-tablet) {
            margin-bottom: 22px;
        }
    }

    &__safe {
        position: absolute;
        top: -14px;
        right: -17px;
        z-index: 2;
        width: 209px;
        height: 100%;
        max-height: 223px;

        @media (min-width: $breakpoint-min-tablet) {
            right: -35px;
            top: 6px;
            transform: none;
            max-height: 320px;
            width: 300px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            right: -49px;
            top: 145px;
            transform: none;
            max-height: 401px;
            width: 377px;
        }

        &:after {
            content: "";
            position: absolute;
            background-image: url("./images/glitch.gif");
            background-repeat: no-repeat;
            background-size: cover;
            z-index: -1;
            transform: rotate(174deg);
            left: 54px;
            top: 57px;
            width: 109px;
            height: 109px;
            display: inline-block;

            @media (min-width: $breakpoint-min-tablet) {
                left: 68px;
                top: 71px;
                width: 162px;
                height: 168px;
            }

            @media (min-width: $breakpoint-min-desktop) {
                left: 100px;
                top: 89px;
                width: 191px;
                height: 207px;
            }
        }

        &::before {
            content: "";
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: contain;
            z-index: 2;
            display: inline-block;
            background-image: url("./images/safe.webp");
        }
    }
}
