@import "../../styles/variables.scss";

.what-you-get {
    &__links-wrapper {
        position: relative;
        z-index: 1;
        margin-top: 40px;

        @media (min-width: $breakpoint-min-tablet) {
            margin-top: 62px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            margin-top: 84px;
        }

        &::after {
            content: "";
            position: absolute;
            z-index: 2;
            display: inline-block;
            width: 78px;
            height: 114px;
            background-image: url("./images/cursor.gif");
            background-repeat: no-repeat;
            background-size: contain;
            left: 23px;
            bottom: -25px;
            pointer-events: none;

            @media (min-width: $breakpoint-min-tablet) {
                width: 102px;
                height: 149px;
                left: 120px;
                bottom: -81px;
            }

            @media (min-width: $breakpoint-min-desktop) {
                width: 150px;
                height: 219px;
                left: 177px;
                bottom: -121px;
            }
        }
    }

    &__second-link {
        margin-top: 5px 0 0;

        @media (min-width: $breakpoint-min-tablet) {
            margin: 0;
        }
    }

    &__title {
        margin: 45px 0 5px;

        @media (min-width: $breakpoint-min-tablet) {
            margin: 70px 0 10px;
        }
    }
}
