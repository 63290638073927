@import "../../styles/variables.scss";

.who-will-take {
    overflow: hidden;
    margin-bottom: 50px;

    @media (min-width: $breakpoint-min-desktop) {
        margin-bottom: 70px;
    }

    &__title {
        margin-top: 40px;
        margin-bottom: 5px;

        @media (min-width: $breakpoint-min-tablet) {
            margin-top: 20px;
            margin-bottom: 10px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            margin-top: 60px;
        }
    }

    &__subtitle {
        margin-bottom: 20px;

        @media (min-width: $breakpoint-min-tablet) {
            margin-bottom: 38px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            margin-bottom: 53px;
        }
    }

    &__cards-container {
        display: flex;
        flex-direction: column;
    }

    &__card {
        margin-bottom: 5px;
        flex-basis: 100%;
        max-width: 100%;
    }

    &__card_video {
        order: 6;
        max-width: 344px;
        min-width: 344px;
        align-self: center;
    }

    @media (min-width: $breakpoint-min-tablet) {
        &__cards-container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr;
            gap: 20px 20px;
            grid-template-areas:
                "block-1 block-2 block-3"
                "block-4 video video"
                "block-5 video video";
        }

        &__card {
            display: flex;
        }

        &__card_1 {
            grid-area: block-1;
        }

        &__card_2 {
            grid-area: block-2;
        }

        &__card_3 {
            grid-area: block-3;
        }

        &__card_video {
            grid-area: video;
            display: block;
            max-width: initial;
            min-width: initial;
        }

        &__card_4 {
            grid-area: block-4;
        }

        &__card_5 {
            grid-area: block-5;
        }
    }

    &__video {
        position: relative;
        padding-bottom: 73.25%;
        margin-top: 25px;

        @media (min-width: $breakpoint-min-tablet) {
            margin-top: 0;
            padding-bottom: 61.25%;
        }

        &::after {
            display: inline-block;
            position: absolute;
            top: -38px;
            left: -20px;
            width: 395px;
            height: 328px;
            content: "";
            background-image: url("./images/ipad.webp");
            background-repeat: no-repeat;
            background-size: contain;
            pointer-events: none;

            @media (min-width: $breakpoint-min-tablet) {
                top: -36px;
                left: -20px;
                width: 487px;
                height: 367px;
            }

            @media (min-width: $breakpoint-min-desktop) {
                top: -45px;
                left: -25px;
                width: 587px;
                height: 478px;
            }

            @media (min-width: 1200px) {
                top: -56px;
                left: -30px;
                width: 687px;
                height: 584px;
            }
        }

        iframe,
        object,
        embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            @media (min-width: $breakpoint-min-tablet) {
                width: 78%;
                height: 99%;
                top: 1px;
                left: 4px;
            }

            @media (min-width: $breakpoint-min-desktop) {
                width: 79%;
                height: 97%;
                top: 5px;
                left: 4px;
            }

            @media (min-width: 1200px) {
                width: 79%;
                height: 97%;
                top: 5px;
                left: 6px;
            }
        }
    }
}
