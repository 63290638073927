@import "../../styles/variables.scss";

.footer {
    overflow: hidden;
    position: relative;
    background-color: $indigo;
    padding-top: 72px;

    &::before {
        content: "";
        position: absolute;
        display: inline-block;
        width: 352px;
        height: 206px;
        background-image: url("./images/map.svg");
        background-repeat: no-repeat;
        background-size: contain;
        bottom: 37px;
        left: 50%;
        transform: translateX(-50%);

        @media (min-width: $breakpoint-min-tablet) {
            width: 751px;
            height: 367px;
            bottom: 22px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            width: 676px;
            height: 463px;
            bottom: -38px;
        }
    }

    &__content {
        position: relative;
        padding-bottom: 57px;

        @media (min-width: $breakpoint-min-tablet) {
            padding-bottom: 45px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            padding-bottom: 80px;
        }
    }

    &__title {
        margin-bottom: 5px;

        @media (min-width: $breakpoint-min-tablet) {
            margin-bottom: 10px;
        }
    }

    &__form-wrapper {
        background-color: $violet-dark;
        margin-top: 22px;

        @media (min-width: $breakpoint-min-tablet) {
            margin-top: 36px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            margin-top: 43px;
        }
    }

    &__contact {
        padding-top: 37px;
        padding-bottom: 57px;

        @media (min-width: $breakpoint-min-tablet) {
            padding-top: 51px;
            padding-bottom: 45px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            padding-top: 67px;
            padding-bottom: 80px;
        }
    }

    &__contacts-phone,
    &__contacts-email {
        display: block;
    }

    &__contacts-phone {
        text-decoration: none;
    }

    &__contact-item {
        margin-top: 30px;

        @media (min-width: $breakpoint-min-desktop) {
            margin-top: 47px;
        }

        > * {
            line-height: 17px !important;

            @media (min-width: $breakpoint-min-desktop) {
                line-height: 22px !important;
            }
        }
    }

    &__contact-item-title {
        margin-bottom: 5px;

        @media (min-width: $breakpoint-min-desktop) {
            margin-bottom: 9px;
        }
    }

    &__copyrights {
        position: absolute;
        right: 0;
        bottom: 17px;

        @media (min-width: $breakpoint-min-tablet) {
            bottom: 48px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            bottom: 75px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            bottom: 85px;
        }
    }
}
