@import "./variables.scss";

.button {
    border-radius: 1px;
    background-color: $gray;
    box-shadow: 7px -7px 20px rgba(0, 0, 0, 0.2);
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    @media (min-width: $breakpoint-min-tablet) {
        padding: 17px 0;
    }

    &_violet {
        background-color: $violet;
    }
}
