@import "../../styles/variables.scss";

.navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 1000;
    width: calc(100% - 16px);
    padding-left: 16px;

    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    background-color: $white;

    &__content-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @media (min-width: $breakpoint-min-desktop) {
            max-width: 1184px;
        }
    }

    &__logo {
        height: 15px;
        width: 50px;
        background-image: url("./images/issp-logo.svg");
        background-size: cover;
        z-index: 5;

        @media (min-width: $breakpoint-min-tablet) {
            height: 24px;
            width: 80px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            height: 30px;
            width: 100px;
        }
    }

    &__content {
        position: fixed;
        z-index: 2;

        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: scroll;

        background: $white;
        padding-top: 80px;
        padding-bottom: 20px;

        visibility: visible;
        opacity: 1;
        transition: visibility 0.3s linear, opacity 0.3s linear;

        @media (min-width: $breakpoint-min-tablet) {
            position: relative;
            visibility: visible;
            overflow: visible;
            opacity: 1;
            padding: 18px;
        }

        &_collapsed {
            visibility: hidden;
            opacity: 0;
            top: -100%;

            @media (min-width: $breakpoint-min-tablet) {
                position: relative;
                visibility: visible;
                opacity: 1;
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (min-width: $breakpoint-min-tablet) {
            flex-direction: row;
        }

        & > li {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            &:not(:first-child):after {
                border-top: solid 1px $indigo;
                content: "";
                position: absolute;
                display: block;
                top: 0;
                left: 50%;
                width: 200px;
                transform: translate(-50%);

                @media (min-width: $breakpoint-min-tablet) {
                    border-top: 0;
                }
            }
        }
    }

    &__item {
        padding: 10px 20px;
        display: inline-block;
        cursor: pointer;
        text-decoration: none;

        font-family: "museo_sans_cyrl100", sans-serif !important;
        font-weight: 250;
        font-size: 20px;
        color: $indigo;
        letter-spacing: 2px;

        @media (min-width: $breakpoint-min-tablet) {
            padding: 0;
            margin: 6px 20px;
            font-size: 14px;
            letter-spacing: initial;
        }

        @media (min-width: $breakpoint-min-desktop) {
            padding: 0;
            margin: 16px 30px;
            font-size: 16px;
        }
    }

    @media (min-width: $breakpoint-min-tablet) {
        &__item-with-sub-navigation {
            position: relative;

            &:hover {
                .navigation__submenu {
                    display: block;
                }
            }
        }
    }

    &__submenu-toggler {
        &:after {
            content: "\ff0b";
            color: $indigo;
            padding: 10px 16px;
            margin-right: -16px;
            position: relative;
            display: inline-block;
        }

        @media (min-width: $breakpoint-min-tablet) {
            display: none;
        }

        &_active {
            &:after {
                content: "\ff0d";
            }
        }
    }

    &__submenu {
        @media (min-width: $breakpoint-min-tablet) {
            position: absolute;
            top: 20px;
            background-color: $white;
            padding-top: 20px;
            width: max-content;

            &:hover {
                display: block;
            }
        }

        @media (min-width: $breakpoint-min-desktop) {
            top: 33px;
        }

        & > li {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (min-width: $breakpoint-min-tablet) {
                align-items: flex-start;
            }
        }
        display: none;

        &_opened {
            display: block;
        }
    }

    &__submenu-item {
        padding: 10px 20px;
        margin: 0;
        max-width: 225px;
        text-align: center;
        line-height: 1.5;

        @media (min-width: $breakpoint-min-tablet) {
            max-width: initial;
            line-height: initial;
            text-align: initial;
            padding: 0 5px 13px;
        }
    }
}
