@import "../../styles/variables.scss";

.cyber-threats-card {
    position: relative;
    padding-left: 55px;
    margin-bottom: 30px;

    @media (min-width: $breakpoint-min-tablet) {
        margin-bottom: 57px;
        padding-left: 67px;
    }

    @media (min-width: $breakpoint-min-desktop) {
        padding-left: 87px;
    }

    &:before {
        position: absolute;
        width: 16px;
        content: " ";
        background-image: url("./images/arrow.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 9px;
        width: 40px;

        @media (min-width: $breakpoint-min-tablet) {
            width: 60px;
            height: 12px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            width: 80px;
            height: 16px;
        }
    }
}
