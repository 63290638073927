@import "../../styles/variables.scss";

.who-will-take-card {
    padding: 26px 14px;
    background-color: $violet-dark;
    display: flex;
    align-items: center;

    &_sky {
        background-color: $sky;
    }
}
