@import "../../styles/variables.scss";

.soc-covers {
    background: linear-gradient(180deg, transparent 0, transparent 65px, $indigo 0, $indigo);
    position: relative;
    z-index: 2;
    overflow-x: clip;

    @media (min-width: $breakpoint-min-tablet) {
        background: linear-gradient(180deg, transparent 0, transparent 20px, $indigo 0, $indigo);
    }

    @media (min-width: $breakpoint-min-desktop) {
        background: linear-gradient(180deg, transparent 0, transparent 25px, $indigo 0, $indigo);
    }

    &::before {
        content: "";
        position: absolute;
        display: inline-block;
        width: 260px;
        height: 63px;
        background-image: url("./images/glitch-1.gif");
        background-repeat: no-repeat;
        background-size: contain;
        top: 172px;
        right: 0;
        transform: translateY(-50%);

        @media (min-width: $breakpoint-min-tablet) {
            width: 577px;
            height: 140px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            left: 0;
            top: 240px;
        }
    }

    &::after {
        content: "";
        position: absolute;
        display: inline-block;
        width: 356px;
        height: 86px;
        background-image: url("./images/glitch-2.gif");
        background-repeat: no-repeat;
        background-size: contain;
        right: 0;
        bottom: 58px;

        @media (min-width: $breakpoint-min-tablet) {
            width: 476px;
            height: 115px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            width: 657px;
            height: 159px;
        }
    }

    &__action-buttons-wrapper {
        position: relative;
        z-index: 1;

        &::after {
            content: "";
            position: absolute;
            z-index: 2;
            display: inline-block;
            width: 182px;
            height: 182px;
            background-image: url("./images/hand.gif");
            background-repeat: no-repeat;
            background-size: contain;
            left: 157px;
            bottom: 51px;
            pointer-events: none;

            @media (min-width: $breakpoint-min-tablet) {
                width: 237px;
                height: 237px;
                left: 275px;
                bottom: 24px;
            }

            @media (min-width: $breakpoint-min-desktop) {
                width: 298px;
                height: 298px;
                left: 493px;
                bottom: 10px;
            }
        }
    }

    &__second-link {
        margin: 5px 0 0;

        @media (min-width: $breakpoint-min-tablet) {
            margin: 0;
        }
    }

    &__third-link {
        margin-top: 30px;
        margin-bottom: 39px;
        position: relative;
        z-index: 2;

        @media (min-width: $breakpoint-min-tablet) {
            margin-top: 44px;
            margin-bottom: 48px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            margin-top: 68px;
            margin-bottom: 64px;
        }
    }

    &__title {
        margin: 46px 0 5px;

        @media (min-width: $breakpoint-min-tablet) {
            margin: 56px 0 10px;
        }
    }

    &__subtitle {
        margin-bottom: 21px;

        @media (min-width: $breakpoint-min-tablet) {
            margin-bottom: 29px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            margin-bottom: 60px;
        }
    }

    &__card-wrapper {
        margin-top: 5px;
        width: 100%;

        @media (min-width: $breakpoint-min-tablet) {
            margin-top: 16px;
            display: flex;
            height: 170px;
        }

        @media (min-width: $breakpoint-min-desktop) {
            height: 200px;
        }
    }
}
